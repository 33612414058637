import * as React from 'react';
import { graphql } from 'gatsby';
import { rem } from 'polished';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment StoryCard_data on Strapi_TechstoryPeopleStory {
    title
    description
    thumbnail {
      alternativeText
      url
      imageFile {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;

type StoryCardProps = {
  layoutId?: string,
  // FIXME: type
  data: any,
  variant: 'wide' | 'small',
};

const Container = styled(motion.article, {
  color: 'text',
  transition: 'color 0.3s',
  textDecoration: 'none',
  ':hover, :focus': {
    color: 'primary',
  },
  variants: {
    width: {
      wide: {
      },
      small: {
        padding: '1rem',
      },
    },
  },
});

const ThumbnailContainer = styled(motion.div, {
  boxSizing: 'border-box',
  width: '100%',
  variants: {
    width: {
      wide: {
        height: rem(500),
      },
      small: {
      },
    },
  },
});

const Thumbnail = styled(Img, {
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
});

const ContentContainer = styled('div', {
  paddingY: '2rem',
  variants: {
    width: {
      wide: {
        paddingX: '2rem',
      },
      small: {
      },
    },
  },
});

const Title = styled(motion.h1, {
  fontSize: '2rem',
  display: 'inline-block',
  margin: 0,
  marginBottom: '2rem',
  variants: {
    width: {
      wide: {
        fontSize: '2rem',
      },
      small: {
        fontSize: '1.2rem',
      },
    },
  },
});

const Description = styled(motion.p, {
  textDecoration: 'none',
  margin: 0,
});

const StoryCard: React.FC<StoryCardProps> = ({
  layoutId,
  data,
  variant = 'small',
}) => {
  return (
    <Container width={variant}>
      {data.thumbnail && (
        <ThumbnailContainer
          layoutId={`story-thumbnail-${layoutId}`}
          width={variant}
        >
          <Thumbnail
            fadeIn={false}
            fluid={data.thumbnail?.imageFile?.childImageSharp?.fluid}
          />
        </ThumbnailContainer>
      )}
      <ContentContainer width={variant}>
        <Title
          layoutId={`story-title-${layoutId}`}
          width={variant}
        >
          {data.title}
        </Title>
        <Description>
          {data.description}
        </Description>
      </ContentContainer>
    </Container>
  );
};

export default StoryCard;
