import type { PageProps } from 'gatsby';

import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import StoryCard from '../components/StoryCard';

export const query = graphql`
  query IndexPage {
    strapi {
      techstoryMainCollections {
        featured {
          urlSlug
          environment
          ...StoryCard_data
        }
        contents {
          urlSlug
          environment
          ...StoryCard_data
        }
      }
    }
  }
`;

// FIXME type
type IndexPageProps = PageProps<any>;

const Container = styled('main', {
  color: 'text',
  minHeight: '100vh',
  marginBottom: '3rem',
});

const CardList = styled('ul', {
  display: 'grid',
  listStyle: 'none',
  paddingLeft: 0,
  gridTemplateColumns: '1fr',
  md: {
    gridTemplateColumns: '1fr 1fr',
  },
});

const CardListItem = styled('li', {
  variants: {
    fullWidth: {
      true: {
        gridColumn: '1 / 3',
      },
      false: {
      },
    },
  },
});

const FullWidthSection = styled('section', {
  paddingTop: '0.5rem',
  lg: {
    $contentWrapper: true,
    paddingTop: '3rem',
  },
});

const Section = styled('section', {
  $contentWrapper: true,
  paddingTop: '3rem',
});

const SectionTitle = styled('h2', {
  fontSize: '1.1rem',
  fontWeight: 400,
  margin: '1rem',
  variants: {
    color: {
      primary: {
        color: 'primary',
      },
      normal: {
        color: 'lowContrast',
      },
    },
  },
});

const StoryLink = styled(Link, {
  display: 'block',
  marginBottom: '2rem',
  transition: 'box-shadow 0.3s',
  textDecoration: 'none',
  boxShadow: 'none',
  ':hover, :focus': {
    boxShadow: '4px 4px 64px 0 rgba(0,0,0,.1)',
  },
  variants: {
    recent: {
      true: {
        border: '1px solid border',
        boxShadow: '4px 4px 16px 0 rgba(0,0,0,.1)',
      },
      false: {
        padding: '0',
      },
    },
  },
});

const IndexPage: React.FC<IndexPageProps> = ({
  data,
  location,
}) => {
  const isPreview = (storyNode: { environment: string }) => {
    const deployEnv = process.env.GATSBY_DEPLOY_ENV || "preview";
    return storyNode.environment === 'production' || deployEnv === "preview"
  }
  const techstoryMainCollection = data.strapi.techstoryMainCollections[0];
  const featuredNode = techstoryMainCollection.featured;
  const archiveStories = techstoryMainCollection.contents
    // Note: 퍼블리시 되기 전 컨텐츠가 연결되어 있으면 null
    .filter(Boolean)
    .filter(isPreview)
  const hasArchiveStories = archiveStories.length > 0;
  return (
    <Container>
      <FullWidthSection>
        <SectionTitle color="primary">
          <strong>Featured Story</strong>
        </SectionTitle>
        {isPreview(featuredNode)
          ? (
            <StoryLink recent to={`/stories/${featuredNode.urlSlug}/`}>
              <StoryCard variant="wide" layoutId={featuredNode.urlSlug} data={featuredNode} />
            </StoryLink>
          )
          : null}
      </FullWidthSection>
      {hasArchiveStories ? (
        <Section>
          <SectionTitle>Archive</SectionTitle>
          <CardList>
            {archiveStories.map((node, i) => (
              <CardListItem key={i}>
                <StoryLink to={`/stories/${node.urlSlug}`}>
                  <StoryCard layoutId={node.urlSlug} data={node} />
                </StoryLink>
              </CardListItem>
            ))}
          </CardList>
        </Section>
      ) : null}
    </Container>
  );
};

export default IndexPage;
